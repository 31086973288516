import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue";
import filters from "@/helpers/filters";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.config.globalProperties.$filters = filters;
app.mount("#app");
