import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import WarrantyLayout from "@/views/warranty/WarrantyLayout.vue";
import ProfileLayout from "@/views/profile/ProfileLayout.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Start",
    redirect: { name: "Login" },
    component: DefaultLayout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/HomeView.vue"),
      },
      {
        path: "/status-nieruchomosci",
        name: "Status",
        component: () =>
          import(
            /* webpackChunkName: "status" */ "@/views/status/StatusView.vue"
          ),
      },
      {
        path: "/dokumentacja",
        name: "Documentation",
        component: () =>
          import(
            /* webpackChunkName: "documentation" */ "@/views/documentation/DocumentationView.vue"
          ),
      },
      {
        path: "/platnosci",
        name: "Payment",
        component: () =>
          import(
            /* webpackChunkName: "Payment" */ "@/views/payment/PaymentView.vue"
          ),
      },
      {
        path: "/gwarancja-i-serwis",
        redirect: { name: "Warranty" },
        component: WarrantyLayout,
        children: [
          {
            path: "gwarancja-i-serwis",
            name: "Warranty",
            component: () =>
              import(
                /* webpackChunkName: "warranty" */ "@/views/warranty/WarrantyView.vue"
              ),
          },
          {
            path: "zglos-sprawe",
            name: "ReportCase",
            component: () =>
              import(
                /* webpackChunkName: "warranty" */ "@/views/warranty/ReportCaseView.vue"
              ),
          },
          {
            path: "sprawy-biezace",
            name: "CurrentCases",
            component: () =>
              import(
                /* webpackChunkName: "warranty" */ "@/views/warranty/CurrentCasesView.vue"
              ),
          },
          {
            path: "archiwum-spraw",
            name: "ArchivalCases",
            component: () =>
              import(
                /* webpackChunkName: "warranty" */ "@/views/warranty/ArchivalCasesView.vue"
              ),
          },
        ],
      },
      {
        path: "/profil",
        component: ProfileLayout,
        children: [
          {
            path: "",
            name: "Profile",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "@/views/profile/ProfileView.vue"
              ),
          },
          /*{
            path: "zmiana-hasla",
            name: "PasswordChange",
            component: () =>
              import(
                /* webpackChunkName: "profile"  "@/views/profile/PasswordChangeView.vue"
              ),
          },*/
        ],
      },
    ],
  },
  {
    path: "/logowanie",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/LoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from) => {
  if (!store.getters["auth/isAuthenticated"] && to.name !== "Login") {
    return { name: "Login" };
  }
  if (store.getters["auth/isAuthenticated"] && to.name === "Login") {
    return { name: "Home" };
  }
});

export default router;
