export interface AuthState {
  id: number | null;
  name: string | null;
  email: string | null;
  accessToken: string | null;
  remember: boolean;
}

export default {
  namespaced: true,
  state: {
    id: null,
    name: null,
    email: null,
    accessToken: localStorage.getItem("user-token") || null,
    remember: false,
  } as AuthState,
  mutations: {
    login(state: AuthState, payload: AuthState) {
      state.id = payload.id;
      state.name = payload.name;
      state.email = payload.email;
      state.accessToken = payload.accessToken;
    },
    update(state: AuthState, payload: AuthState) {
      state.email = payload.email;
    },
    logout(state: AuthState) {
      state.id = null;
      state.name = null;
      state.email = null;
      state.accessToken = null;
    },
  },
  actions: {},
  getters: {
    isAuthenticated(state: AuthState) {
      return state.accessToken !== null;
    },
  },
};
